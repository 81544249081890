import React from "react";
import type {
  Category,
  Navigation as NavigationType,
  Page,
} from "@payload-types";
import { Link, NavLink } from "@remix-run/react";
import { twMerge } from "tailwind-merge";

type NavigationItem = NonNullable<NavigationType["main"]>[0];
type ItemProps = {
  item: NonNullable<NavigationType["main" | "footer"]>[0];
  itemClassName?: string;
  activeItemClassName?: string;
  type: "main" | "footer";
  isSubnavigation?: boolean;
};

type Reference = Category | Page;

type Props = {
  items: NavigationType["main" | "footer"] | null | undefined | [];
  className?: string;
  itemClassName?: string;
  activeItemClassName?: string;
  type?: "main" | "footer";
  isSubnavigation?: boolean;
};

const NavigationItem: React.FC<ItemProps> = ({
  item,
  itemClassName,
  activeItemClassName,
  type,
  isSubnavigation,
}) => {
  return (
    <div className="relative break-inside-avoid">
      {item.link.type === "reference" ? (
        <NavLink
          to={
            (item.link.reference?.value as Reference)?.url ||
            item.link.url ||
            ""
          }
          target={item.link.newTab ? "_blank" : "_self"}
          rel="noopener noreferrer"
          className={({ isActive }) =>
            twMerge(
              "hover:text-blu-500 peer mb-2 text-gray-800 transition-colors duration-200 ease-in-out",
              isSubnavigation && "text-gray-500",
              itemClassName,
              isActive && activeItemClassName,
            )
          }
          prefetch="intent"
        >
          {item.link.label}
        </NavLink>
      ) : (
        <Link
          to={item.link.url || ""}
          target={item.link.newTab ? "_blank" : "_self"}
          rel="noopener noreferrer"
          className={twMerge(
            "hover:text-blu-500 peer mb-2 text-gray-800 transition-colors duration-200 ease-in-out",
            itemClassName,
          )}
        >
          {item.link.label}
        </Link>
      )}
      {item.subnavigation?.length ? (
        <Navigation
          items={item.subnavigation}
          className={twMerge(
            type === "main"
              ? "-translate-x-4 flex-col gap-4 bg-white p-4 transition-all hover:visible hover:opacity-100 peer-hover:visible peer-hover:opacity-100 sm:invisible sm:absolute sm:absolute sm:items-start sm:opacity-0"
              : "relative",
            type === "footer" && "mt-1 space-y-1",
          )}
          isSubnavigation={true}
          itemClassName={itemClassName}
          activeItemClassName={activeItemClassName}
          type={type}
        />
      ) : null}
    </div>
  );
};

export const Navigation: React.FC<Props> = ({
  items,
  itemClassName,
  activeItemClassName,
  type = "main",
  isSubnavigation = false,
  className,
}) => {
  // each item renders as either an internal link, an external link with an icon or text, or another navigation
  return items ? (
    <nav
      className={twMerge(
        type === "main" && "flex gap-4 sm:items-center",
        type === "footer" && "space-y-2",
        !isSubnavigation &&
          "sm:columns-2 md:columns-3 lg:columns-4 xl:columns-5",
        className,
      )}
    >
      {items?.map((item) => (
        <NavigationItem
          item={item}
          key={item.id}
          itemClassName={itemClassName}
          activeItemClassName={activeItemClassName}
          type={type}
          isSubnavigation={isSubnavigation}
        />
      ))}
    </nav>
  ) : (
    <></>
  );
};

export default Navigation;
