import { useRouteLoaderData } from "@remix-run/react";
import { useEffect, useState, createContext, useContext } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import Button from "~/components/layout/Button";
import type { loader as rootLoader } from "~/root";

export type CookieConsent = boolean | null;

export type CookieConsentContext = {
  consent: CookieConsent;
  setConsent: (consent: CookieConsent) => void;
};

export const CookieContext = createContext<CookieConsentContext>({
  consent: null,
  setConsent: () => {},
});

export const useCookieConsent = () => useContext(CookieContext);

export const CookieConsentProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [state, setState] = useState<boolean | null>(false);
  const [cookies, setCookie] = useCookies(["cookie-consent"]);

  useEffect(() => {
    if (cookies["cookie-consent"] === true) {
      setState(true);
    } else if (cookies["cookie-consent"] === false) {
      setState(false);
    } else {
      setState(null);
    }
  }, [cookies, state]);

  return (
    <CookieContext.Provider
      value={{
        consent: state,
        setConsent: (consent) => {
          setCookie("cookie-consent", consent, {
            path: "/",
            maxAge: 60 * 60 * 24 * 365, // 1 year
          });
          setState(consent);
        },
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

const Cookies = () => {
  const { t } = useTranslation();
  const { urlPrivacyPolicy } = useRouteLoaderData<typeof rootLoader>("root")
    ?.site || {
    site: { urlPrivacyPolicy: "" },
  };
  const { consent, setConsent } = useCookieConsent();
  const onAccept = () => {
    setConsent(true);
  };
  const onReject = () => {
    setConsent(false);
  };

  return consent === null ? (
    <div className="border-1 w-2xl fixed bottom-4 left-4 right-4 mx-auto max-w-[96vw] rounded-md bg-white p-4 shadow-lg">
      <p
        className="text-sm"
        dangerouslySetInnerHTML={{
          __html: t("cookies.banner.text", {
            urlPrivacyPolicy,
          }),
        }}
      />
      <div className="mt-4 flex justify-end gap-4">
        <Button onClick={onReject}>{t("cookies.banner.reject")}</Button>
        <Button onClick={onAccept} look="yello">
          {t("cookies.banner.accept")}
        </Button>
      </div>
    </div>
  ) : null;
};

export default Cookies;
